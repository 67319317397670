.icon-module{
    main{
        .list-grid-3 {
            margin-right: -50px;
            margin-left: -50px;
            justify-content: center;

            li{
                padding: 4*$pad 10*$pad 0;                
                text-align: center;

                figure{
                    img{
                        max-width: 215px;
                    }
                }
            }
        }        
    }

    @include media(768px, min){
        main{
            .list-grid-3 {    
                li{
                    padding: 10*$pad 10*$pad 0;                    
                }
            }        
        }
    }
}