// FOR FEED TABS
.tabs-module{
  position: sticky;
  z-index: 11; 

  small{
    margin-bottom: 10px;
  }

  .tabs{    
    position: sticky;
    z-index: 11;
        nav{      
            border-bottom:1px solid $grey;             
            @include transition(all, .25s);      
            @include selfClearing;

            ul{
                @include flex-wrap;           
                justify-content: space-evenly; 
                padding:0;
                @extend %listless;                                           

                li{
                    padding:3*$pad 0;                    
                    margin-bottom: -1px;                                  
                    position: relative;
                    border-bottom:4px solid $trans;   
                    text-transform: uppercase; 
                    font-weight: $bold;
                    @include font-size(14px, 16px);               

                    &:not(.active){
                        &:hover{
                            border-bottom:4px solid $primary-color;
                            color: $primary-color;  
                            cursor: pointer;
                        }
                    }
                    
                    &.active{
                        border-bottom:4px solid $primary-color;
                        color: $primary-color;                                   
                    }
                }
            }
        }

        &.with-number{
            nav{
                li{
                    padding-bottom: 8*$pad; 
                    span{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 54px;
                        height: 54px;
                        @include border-radius(50%);
                        background-color: $lgrey;
                        color: $white;
                        position: absolute;
                        bottom: -27px;
                        left: 50%;
                        margin-left: -27px;
                    }

                    &:not(.active){
                        &:hover{
                            span{                            
                                background-color: $primary-color;                            
                            }
                        }
                    }
                    &.active{                        
                        span{                            
                            background-color: $primary-color;                            
                        }              
                    }
                }
            }
        }
        .tabsContainer{
            ul.tabs{
                flex-wrap:wrap;
                padding: 0px;

                li.tab{
                    width:100%;
                    align-items:flex-start;
                    display:none;

                    &.active{
                        display:block;
                    }
                    .tabInfo{
                        padding-top:10px; 
                        
                        .video-block{
                            position: relative;
                            height: 250px;
                            @include border-radius(10px);

                            .play-icon{
                                display: flex;
                                align-items: center;
                                justify-content: center;                                
                                width: 60px;
                                height: 60px;
                                @include border-radius(50%);
                                @include font-size(24px, 16px);
                                background-color: $white;
                                color: $primary-color;
                                @include middle;
                                z-index: 9;
                                appearance: none;
                                border: none;
                                outline: none
                            }

                            video {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                vertical-align: top;                                
                            }

                            img.cover {
                                @include border-radius(10px);
                            }

                            .trigger-video {
                                position: absolute;
                                top: calc(100% - 15px);
                                right: 20px;
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                background: $primary-color;
                                border: none;
                
                                &::before {
                                    content: "\f04b";
                                    @extend .fas;
                                    position: absolute;
                                    top: 50%;
                                    left: calc(50% + 1px);
                                    transform: translate(-50%, -50%);
                                    color: $white;
                                    @include font-size(14px, 16px);
                                }
                            }
                        }
                    }                    
                }
            }
        }

        @include media(768px, min){
            nav{
                ul{    
                    li{                        
                        @include font-size(16px, 16px);
                    }
                }
            }

            .tabsContainer{
                ul.tabs{
                    li.tab{
                        .tabInfo{
                            padding-top:40px;
                        }                        
                    }
                }
            }
        }          
        
        @include media(992px, min){ 
            .tabsContainer{
                ul.tabs{
                    li.tab{
                        .tabInfo{ 
                            .video-block{
                                margin-left: 35px; 
                            }
                        }                        
                    }
                }
            }
        } 
    }

    &.event-tabs{
        .tabs{
            nav{      
                @include media(768px, min){
                    ul{
                        justify-content: center;                   
        
                        li{
                            padding:3*$pad 10*$pad;                                                
                        }
                    }
                }
            }
        }
    }
}


