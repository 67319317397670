.home-radial-module{
    .intro-text{
        h4{
            font-weight: $bold;
        }        
    }    

    .inner {
        .row-col {
            .column-12 {
                text-align: center;
            }
        }
    }

    img.desktop {
        display: none;
    }

    .radial-module-split {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -1rem;

        > div {
            flex: 0 0 100%;
            padding-top: 1rem;
            padding-left: .5rem;            

            img {
                width: 100%;
                height: auto;
            }

            @include media(525px, min) {
                flex: 0 0 50%;
                padding-right: .5rem;
            }

            @include media(768px, min) {
                flex: 0 0 33.33%;
            }
        }

        @include media(525px, min) {
            width: calc(100% + 1rem);
            margin-left: -.5rem;
        }
    }

    @include media(648px, min) {
        
        img.mobile {
            display: none;
        }

        img.desktop {
            display: block;
        }

    }
}