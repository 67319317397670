.fey-facts-module{
    small{                
        margin-bottom: 2*$mar;        
    } 
    h3{
        font-weight: $bold;         
    }
    h5{
        color: $white;
        margin-bottom: 0;
    }    
}