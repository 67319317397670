body {    
    @include font-size(16px, 16px); 
    font-family: $Montserrat;
    font-weight: $regular;
    color: $text-color;    
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;  
    -webkit-text-size-adjust: 100%; 
    overflow-x: hidden;
    scroll-behavior:smooth;
    background:#ffffff;
    
    @media (min-width:768px){
        @include font-size(16px, 16px); 
    }  
}
a{
    text-decoration:none;     
    color: $text-color;

    &:hover{
        text-decoration:none;
        color: $gbltxt-hover;
        //@include transitions(all .3s 0s ease-in-out);  
    }
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
}

#window{
    width: 100%;
    min-height: 100%;
    @include transition(transform, .4s); 
    @include selfClearing;    
    transition: transform .5s cubic-bezier(.075,.82,.165,1);    
}
.container{
    max-width: 1280px; 
    width:100%;  
    padding-left:15px;
    padding-right:15px;   
    margin: 0 auto;    
    position: relative;
    @include selfClearing;     
}
.inner{
    max-width: 900px;  
    width:100%;  
    padding-left:15px;
    padding-right:15px;   
    margin: 0 auto;    
    position: relative;
    @include selfClearing;     
}

.body-wrap{
    min-height:400px;    
    width:100%;
    margin: 0 auto;
    background-image: url(../images/body-bg.png), url(../images/body-bg.png);
    background-position: left -250px top -250px, right -250px top 500px; 
    background-repeat: repeat-y, repeat-y;
    overflow: hidden;
    @include selfClearing;    
}
.wrapper{
    min-height:400px;   
    width:100%;  
    padding: 0 10*$pad;  
    @include selfClearing;

    @media(min-width:992px){
        padding: 0 19*$pad; 
    }    
}

.none{display: none !important;}
.text-left{text-align:left;}
.text-right{text-align:right;}
.text-center{text-align:center;}

.shadow{@include box-shadow(0 0px 10px 0px rgba(0, 0, 0, 0.2));}

.gbl{
    // 30, OR 45
    margin-top: 60px;
    margin-bottom: 60px;

    &-80{
        margin-top: 80px;
        margin-bottom: 80px;
    }
}
@include media(1024px, min){
    .gbl{
        margin-top: 75px;
        margin-bottom: 75px;

        &-80{
            margin-top: 95px;
            margin-bottom: 95px; 
        }
        &-top{            
            &-80{margin-top: 95px;}
        }  
    }
}

p{
    line-height:1.6;
    margin:4*$mar 0;   
}
small{    
    letter-spacing: 1px;    
    display: block;
    font-weight: $bold;
    color: $primary-color;
    text-transform: uppercase;
    @include font-size(12px, 16px);
}

// Heading sizes 
h1, h2, h3, h4, h5, h6 { 
    font-family: $Montserrat;   
    color: $text-color;
    font-weight: $regular;
    padding: 0;
    margin: 0 0 5*$mar;
    position: relative;
    line-height: 1.2;    
}
h1{
    @include font-size(65px, 16px);
    span{
        color: $primary-color;
    }
    &.main-header-text{
        margin-bottom: 6*$mar;
        line-height: 1.2;
        font-weight: $bold;
        @include font-size(38px, 16px);
    }
}
h2{
    @include font-size(38px, 16px);    
    font-weight: $bold;
    span{
        color: $primary-color;
    }
}
h3{
    @include font-size(30px, 16px);  
}
h4{
    @include font-size(24px, 16px); 
    font-weight: $bold;
}
h5{
    @include font-size(20px, 16px);
    line-height: 1.4;
}
h6{
    @include font-size(18px, 16px);        
}

@include media(1024px, max){ 
    h1{
        @include font-size(50px, 16px);
        &.main-header-text{
            @include font-size(34px, 16px);
        }
    }
    h2{@include font-size(34px, 16px);}
    h3{ @include font-size(26px, 16px);}
}

@include media(768px, max){
    h1{
        @include font-size(40px, 16px);
        &.main-header-text{
            @include font-size(30px, 16px);
        }
    }
    h2{@include font-size(30px, 16px);} 
    h3{@include font-size(24px, 16px);}   
}

.site-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, .45);    
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 1rem;
    z-index: 1001;    

    &__inner {

        margin: auto;
        max-width: 800px;
    }

    .close-popup {
        position: absolute;
        top: -15px;
        right: -15px;
        appearance: none;
        background: none;
        border: none;
        outline: none;
        width: 40px;
        height: 40px;
        background: $black;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition(all, .175s);
        z-index: 2;

        &::before {
            content: "\f00d";
            @extend .fas;
            font-weight: 300 !important;
            @include font-size(24px, 16px);
            color: $white;
            font-size: .875rem;
        }      
        
        &:hover {
            background: $primary-color;
        }

        @include media(768px, min) {
            height: 55px;
            width: 55px;
            top: calc(-55px /2);
            right: calc(-55px / 2);

            &::before {
                font-size: 1.25rem;
            }
        }
    }

    &:not(.open) {
        z-index: -1;
        opacity: 0;
    }

    video {
        background: #efefef;
    }
}

body.popup-open {
    overflow: hidden;
}