/*
Radiator Digital
http://www.radiatordigital.com
*/

//===================================================================//
// =====================  BASE VARIABLES   ======================== //
//=================================================================//

// DIMENSIONS & GRID
// --------------------------------
@use "sass:math";

$desktop: 1180px;
$tablet: 960px - 1px;
$mobile: 780px - 1px;
$columns: math.div(100%, 12);
$dmargin: math.div(20px, $desktop) * 100%;
$mmargin: math.div(10px, $mobile) * 100%;
$oneThird:math.div(100, 3)*1%;
$twoThird:math.div(100, 3)*2%;

$max: 1920px; 
$mw: 1500px;
$th: 1000px;
$cw: 900px;
$sw: 700px;
$section: 40px;
$sectionlarge: 60px;
$unit: math.div(1, 12)*100%;

// Variables Declaration
// --------------------------------

$gbltxt-hover: #20a5df; 
$primary-color: #20a5df;
$secondary-color: #ffa200;
$text-color: #000000;
$dark-blue: #001135;

$Montserrat: 'Montserrat', sans-serif;;


// FONT-WEIGHT - $MAIN
// --------------------------------
$slight: 100;
$light: 300;
$regular: 400;
$med: 500;
$sum-med: 600;
$bold: 700;
$strong: 700;
$weightBlack: 900;

// BASE COLOR SET
// --------------------------------

$trans: rgba(#FFFFFF, 0);
$white: #fff;
$black: #000;
$w: #fff;
$b: #000;
$ddgrey: #444;
$dgrey: #666;
$mgrey: #8c8c8d;
$lmgrey: #e5e5e5;
$lgrey: #e5e5e5;
$slgrey: #f4f4f4;
$grey: #cecece;
$rad: #ff000d;
$red: #C81E26;
$fb: #3b5998;
$tw: #288EC3;
$gplus: #dd4b39;
$yt: #bb0000;
$vine: #00bf8f;
$li: #007bb6;
$inst: #517fa4;
$pnt: #cb2027;
$tumblr: #35465c;
$radNja: #ff5225;
$radPnk: #db1561;
$rss: $radNja;
$linkedin: $li;
$snapchat: #fffc00;
$vk: #4c75a3;
$weibo: #df2029;
$weixin: #10D21C;
$green: #10D21C;


// FX
// --------------------------------
$shadow: 0px 0px 5px 0px #333;  // Global Box Shadow Style (if applicable)
$headShad: 0px 0px 10px $black;


// SELECTORS
//---------------------------------
$allHeadings: 'h1,h2,h3,h4,h5,h6';

// SOCIAL ICONS
// --------------------------------
$social: (
	'twitter': $tw, 
	'facebook': $fb, 
	'pinterest': $pnt, 
	'youtube': $yt, 
	'linkedin': $li,
	'linkedin-in': $li, 
	'instagram': $inst,
	'google-plus': $gplus,
	'vk': $vk,
	'weixin': $weixin,
	'weibo': $weibo,
	'envelope': $black,
);

