.cards{
    display:block;
    margin:0;
    padding:0;    
    list-style:none;
    width:100%;
    height: 100%;

    [class*=card-]{
        position:relative;  
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;                     

        footer{
            margin-top:auto;
        }
	}

    .card-text-icon{
        padding:4*$pad;  
        min-height: 250px; 
        color: $white;
        @include gradient(#005276, #20a5df);
        @include border-radius(10px);
        
        figure{
            width:81px;
            height: 81px;          
            margin: 0px;
            overflow: inherit;
            //background-color: #e3f8ff;
            //@include border-radius(50%); 
            display: flex;
            align-items: center;
            justify-content: center;          
        }
        header{
            margin:5*$mar 0 2*$mar;  
            
            h3{
                color:$white;
                margin-bottom: $mar;                 
            }
            h6{
                color:$white;                
                margin-bottom: $mar;
                font-weight: $sum-med;                              
            }
        }
        main{
            margin-bottom:4*$mar;
            strong{
                display: block;
            }
            p{
                margin: $mar 0 0;
                line-height: 1.8;
                @include font-size(12px, 16px);
            }            
        }        
    }

    .card-text{
        @extend .card-text-icon;
        min-height: 150px;

        header{    
            margin:3*$mar 0; 

            h4{
                color:$white;
                margin-bottom: 0;
                line-height: 1.2;
                @include font-size(20px, 16px); 
            }
        }               
    }

    .card-company-info{
        figure{
            height: 200px;
            background: $grey;                
            margin:0 0 15px;
            @include border-radius(10px);     
            
            img {
                border-radius: 10px;
            }
        }
        main{            
            h5{
                color: $primary-color;
                font-weight: $regular; 
                min-height: 90px;               
                @include font-size(20px, 16px);
            } 
            h6{
                color: $primary-color;
                font-weight: $bold; 
                min-height: 60px;               
                @include font-size(16px, 16px);
            }           
        }
        footer{
            font-weight: $bold;
            text-transform: uppercase;
            @include font-size(13px, 16px);
        }
    }

    .card-team-info{
        @extend .card-company-info;

        figure {
            .trigger-video {
                position: absolute;
                top: calc(100% - 15px);
                right: 20px;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: $primary-color;
                border: none;

                &::before {
                    content: "\f04b";
                    @extend .fas;
                    position: absolute;
                    top: 50%;
                    left: calc(50% + 1px);
                    transform: translate(-50%, -50%);
                    color: $white;
                    @include font-size(14px, 16px);
                }
            }
        }

        main{
            min-height: 96px;
            h4{
                font-weight: $med;
                margin-bottom: $mar;
                @include font-size(22px, 16px);
            }
            h6{                    
                color: $primary-color;
                font-weight: $bold;  
                margin-bottom: 2*$mar;
                min-height: auto;                  
            }            
        }        
    }

    .card-news{
        @extend .card-company-info;
        figure{
            .dm-box{
                position: absolute;
                right: 10px;
                top:10px;
                z-index: 9;
                //padding-top:5px;
                background: $secondary-color;
                color: $white;
                width: 40px;
                height: 40px;
                @include border-radius(50%);
                @include font-size(12px, 16px);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
            
                span{
                  display: block;
                  line-height: 1;
                  font-weight: $med;

                  &:nth-child(3) {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                  }
                }
            }
        }
        main{
            min-height: 112px;

            h6{
                min-height: auto; 
                margin-bottom: 2*$mar; 
                font-weight: $med;
                line-height: 1.4;             
            }
        }
    }

    .card-event{
        @extend .card-company-info;

        main{
            &.event-block{
                .date-time{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    width: calc(100% + 1rem);
                    margin-left: -.5rem;

                    span{
                        display: block;
                        font-weight: $bold;                    
                        padding: 0 .5rem;
                    }

                    small{
                        color: $text-color;
                        font-weight: $bold;
                        margin: 3px 0;
                        flex: 0 0 100%;
                        @include font-size(12px, 16px);
                        padding: 0 .5rem;
                    }
                }
                .live-event{
                    @extend .date-time; 
                    span{
                        @include font-size(14px, 16px);
                    }
                }
            }
        }
    }

    @include media(768px, min){
        .card-text-icon{
            header{
                margin:5*$mar 0;                 
                h3{                    
                    margin-bottom: 4*$mar;                    
                }
            }
        }

        .card-text{
            header{
                min-height: 55px;
                margin:3*$mar 0; 
    
                h4{                    
                    @include font-size(22px, 16px); 
                }
            }
        }
    }
}













