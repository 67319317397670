.slider-module{    
    .howitwork-slider {        
        margin: 80px 0 50px;
        margin: 0 -8px;
        
        @extend .slideSet;
        .flickity-prev-next-button{            
            background: $white;
            @include padding(0px);            
        }

        .slider:not(.is-selected) .caption {
            transform: scale(.85) !important;
        }

        .slider {
            width: calc(50% - 1rem);
            padding: 0 8px;
            display: flex;
            align-items: stretch;
            min-height: 100%;

            .caption{
                text-align: center;
                padding:6*$pad 4*$pad;                
                color: $white;
                @include gradient(#005276, #20a5df);
                @include border-radius(10px);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column;     
                @include transition(transform, .175s);
           

                span{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    margin: 0 auto 25px;
                    @include border-radius(50%);
                    border:1px solid $white;
                    color: $white;
                    font-weight: $bold;
                    @include font-size(18px, 16px);
                }
                p{
                    margin:0px;
                    line-height: 1.2;
                    font-weight: $sum-med;
                    @include font-size(13px, 16px);
                }                               
            }
        }  
        
        @include media(768px, min){
            .slider {
                width: calc(35% - 1rem);
                padding: 0 8px;

                .caption{                    
                    padding:4*$pad 8*$pad;  
                    min-height: 290px;                    
    
                    span{                        
                        width: 76px;
                        height: 76px;
                        margin: 0 auto 25px;                        
                    }                                                   
                }
            }
        }
    }
}
