.resources-module{
    a{
        color: $primary-color;
        &:hover{
            color: $secondary-color;
        }
    }
    header{
        h1,
        h2{
            line-height: 1.2;
            font-weight: $bold;
            @include font-size(30px, 16px);
        }        
    }

    .sub-header{
        h4{
            margin-bottom: 10px;
        }   
        .cta-blue-border{
            margin-right: 0;
        }     
    }

    main{
        margin: 30px 0;
        @include selfClearing;

        ul{
            @extend %listless;
            border-top: 1px solid $grey;
            float: unset !important;
            
            li{                
                border-bottom: 1px solid $grey;                
                width: 100%;              
                float: unset !important;
                opacity: 0;
                position: absolute;
                pointer-events: none;
                
                &.download{
                    display: flex;  
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 0;

                    .pdf-download{
                        width: 34px;
                        height: 34px;
                        padding: 5px;
                        text-align: center;
                        color: $secondary-color;
                        border: 2px solid $secondary-color;
                        @include border-radius(50%);

                        &:hover{
                            color: $primary-color;
                            border: 2px solid $primary-color;
                        }
                    }

                    &.download-form-open {
                        a.pdf-download {

                            color: $grey !important;
                            border-color: $grey !important;

                            i {
                                &::before {
                                    content: "\f00d";
                                }
                            }
                        }
                    }
                }

                &.accordion{
                    .acc-head{
                        cursor: pointer;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        @extend .fa-plus;                        
            
                        &::before{
                            @extend .fal; 
                            position:absolute;
                            right:0px;
                            top:11px; 
                            color:$primary-color;
                            @include font-size(18px, 16px); 
                            width: 34px;
                            height: 34px;
                            padding: 7px;
                            text-align: center;                           
                            border: 2px solid $primary-color;
                            @include border-radius(50%);             
                        } 
                    }

                    &.active{
                        .acc-head{                                                    
                
                            &::before{
                                content: '\f068';            
                            } 
                        }
                    }

                    .acc-info{
                        padding: 0 0 20px;

                        h4{
                            margin-bottom: 10px;
                        }

                        p{
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }                

                &.visible {
                    opacity: 1 !important;
                    position: relative !important;
                    pointer-events: all !important
                }
            }
        }
    }

    footer{
        @include font-size(18px, 16px);
        line-height: 1.4;
    }

    @include media(768px, min){
        header{
            h1,
            h2{           
                @include font-size(38px, 16px);
            } 
        } 

        .sub-header{
            display: flex;
            align-items: center;
    
            h4{
                margin-bottom: 0;
            }
            .right{
                margin-left: auto;
            }
        }
    }
}