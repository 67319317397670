.contact-module{
    .contact-office{
        background-color: $slgrey;
        padding: 6*$pad 8*$pad;
        margin: 2*$mar 0;        
        @include border-radius(10px);

        a{
            color: $primary-color;
            &:hover{
                color: $secondary-color;
            }
        }
        h5{
            color: $primary-color;
            font-weight: $bold;
            margin-bottom:2*$mar;
            text-transform: uppercase;
            @include font-size(20px, 16px);
        }
        ul{
            @extend %listless;
            min-height: 195px;
            margin-bottom: 6*$mar;

            li{
                float: none;
                padding: 5px 0;
                @include font-size(18px, 16px);
            }
        }
        .cta-blue-border{
            .fa-map-marker-alt{
                @include font-size(16px, 16px);
                margin-right:5px;
            }
        }
    }  
    
    .list-grid-3{        
        li{
            margin: 0; 
            
            a{
                color: $white;

                &:hover{
                    color: $secondary-color;

                    .contact-info{                        
                        p{
                            color: $secondary-color;
                        }
                    }
                }
            }
        } 
    }
    
    .contact-info{
        padding:4*$pad;  
        margin: 2*$mar 0;
        min-height: 160px; 
        color: $white;
        @include gradient(#005276, #20a5df);
        @include border-radius(10px);
        @include flex-width(100%);
        display: flex;
        flex-direction: column;
        justify-content: center;        

        h5{
            color: $white;
            font-weight: $bold;
            margin-bottom: $mar;
            width: 100%;
            text-transform: uppercase;
            @include font-size(20px, 16px);
        }
        p{
            margin: 0;
        }
    }

    @include media(768px, min){
        .contact-office,
        .contact-info{
            margin: 4*$mar 0;  
        }

        .list-grid-3{
            margin-right: -12px;
            margin-left: -12px;
            li{
                padding-left:12px;
                padding-right:12px;                
            } 
        }
    }
    
}


.hotlinks {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    list-style-type: none;
    padding-left: 0 !important;
    margin: 0 !important;
    padding-top: 2rem;

    li {
        a {

            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: .75rem;

            span {
                color: $white;
            }

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        margin-top: -.75rem;
        padding-top: 0;
    }
}