*, *::before, *::after{
    @include box-sizing;
}

::selection {
    background: #efefef;
}
:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

html.mm-blocking{
    overflow: visible;
}
body,
html,
header,
footer,
section,
article,
main,
nav{
    width: 100%;
    display: block; 
    margin: 0;
    padding: 0;
    position: relative;
}
div{
    position: relative;
}
input{max-width: 100%;}
blockquote{margin: 0;}


img, svg{
    height: auto;
    max-width: 100%;
}
a:focus {
    outline: none;    
}

img,
svg,
video{  
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;

    &.cover{
        -o-object-fit: cover;
        object-fit: cover;
        @include middle;
        width:100%;
        height:100%;
        background-color: $primary-color;
    }
}

figure {
    display: block;
    margin: 0 auto;
    position: relative;
    @include selfClearing;

    img{        
        display: inline-block;
        @include transitions(all .5s 0s ease-in-out);
    }
}


ul.unList,
ol.unList{
    list-style-type:none;
    margin:0;
    padding:0;
    display:flex;

    > li{
        display:flex;
    }

    > li > a{
        display:flex;
        width:100%;
        text-decoration:none;
    }
}

.right-img,
.left-img{    
    margin: 0 0 20px;
    overflow: hidden;
    display: inline-block;
    @include border-radius(10px);

    img{
        display: block;
    }
}

@media (min-width:768px) {
    .modal-small {max-width:450px; width:100%;}
    .modal-sm {max-width:650px; width:100%;}
    .modal-md {max-width:850px; width:100%;}
    .modal-lg {max-width:1050px; width:100%;}

    .right-img{
        float: right;
        margin: 0 0 20px 20px;
    }
    .left-img{
        float: left;
        margin: 0 20px 20px 0;        
    }
}


.pt{
    &-0{padding-top: 0px;}
    &-10{padding-top: 10px;}
    &-20{padding-top: 20px;}
    &-30{padding-top: 30px;}
    &-40{padding-top: 40px;}
    &-50{padding-top: 50px;}
	&-60{padding-top: 60px;}
    &-70{padding-top: 70px;}
    &-80{padding-top: 80px;}
    &-90{padding-top: 90px;}
    &-100{padding-top: 100px;}
}
.pb{
    &-0{padding-bottom: 0px;}
    &-10{padding-bottom: 10px;}
    &-20{padding-bottom: 20px;}
    &-30{padding-bottom: 30px;}
    &-40{padding-bottom: 40px;}
    &-50{padding-bottom: 50px;}
	&-60{padding-bottom: 60px;}
    &-70{padding-bottom: 70px;}
    &-80{padding-bottom: 80px;}
    &-90{padding-bottom: 90px;}
    &-100{padding-bottom: 100px;}
}
.mt{
    &-0{margin-top: 0px;}
    &-10{margin-top: 10px;}
    &-20{margin-top: 20px;}
    &-30{margin-top: 30px;}
    &-40{margin-top: 40px;}
    &-50{margin-top: 50px;}
	&-60{margin-top: 60px;}
    &-70{margin-top: 70px;}
    &-80{margin-top: 80px;}
    &-90{margin-top: 90px;}
    &-100{margin-top: 100px;}
}
.mb{
    &-0{margin-bottom: 0px;}
    &-10{margin-bottom: 10px;}
    &-20{margin-bottom: 20px;}
    &-30{margin-bottom: 30px;}
    &-40{margin-bottom: 40px;}
    &-50{margin-bottom: 50px;}
	&-60{margin-bottom: 60px;}
    &-70{margin-bottom: 70px;}
    &-80{margin-bottom: 80px;}
    &-90{margin-bottom: 90px;}
    &-100{margin-bottom: 100px !important;}
}


