// GBL VARS
$gutter:30px;
$pad:5px;
$mar:5px;
$trans:transparent;

$w-xxl:1600px;
$w-xl:1360px;
$w-md:1024px;
$w-sm:768px;
$w-xs:500px; 

// == FONTS / ICONS
@import 'font-awesome/fontawesome'; 
@import 'font-awesome/brands';
@import 'font-awesome/duotone';
@import 'font-awesome/light';
@import 'font-awesome/regular';
@import 'font-awesome/solid';
@import 'font-awesome/v4-shims';  

// LIBRARY
@import '_lib/_mixins'; 
@import '_lib/_variables';
@import '_lib/_normalize';
@import '_lib/_grid';
@import '_lib/_placeholders';

// == Vendor
@import 'vendor/_flickity';
@import 'vendor/_jquery.mmenu.all';

// GBL
@import '_gbl/_globals';
@import '_gbl/_gblType';

// ELEMS
@import '_elems/_cards';
@import '_elems/_button';
@import '_elems/_animation';
@import '_elems/_breadcrumb';
@import '_elems/_social.media';
@import '_elems/_slider';
@import '_elems/_ul.li.list';
@import '_elems/_form';
@import '_elems/_loader';
@import '_elems/cookies';

// MODULES
@import '_modules/_header';
@import '_modules/_banner';

@import '_modules/_home.intro.module';
@import '_modules/_home.radial.module';

@import '_modules/_fey.facts.module';
@import '_modules/_card.intro.text.module';
@import '_modules/_resources.module';
@import '_modules/_media.text.module';
@import '_modules/_btn-banner.module';


@import '_modules/_testimonial';
@import '_modules/_tabs.module';
@import '_modules/_story.module';
@import '_modules/_team.module';
@import '_modules/_news.module';
@import '_modules/_events.module';
@import '_modules/_icon.module';
@import '_modules/_slider.module';
@import '_modules/_form.module';
@import '_modules/_management.module';
@import '_modules/_vacancy.module';
@import '_modules/_logo.module';
@import '_modules/_fliter.list';
@import '_modules/_contact.module';
@import '_modules/_page.search';
@import '_modules/_video';

@import '_modules/_footer';


// LAYOUT


// == DESKTOP & MOBILE MENU
@import '_navbar/_navbar'; 
@import '_navbar/_mobileMenu';

