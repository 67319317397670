.management-module{
    header{
        margin-bottom:40px;
    }
    .list-grid-2{
        margin-left:-45px;
        margin-right:-45px;

        li{
            padding-left:45px;
            padding-right:45px;

            .card-team-info{        
                figure {
                    height: 230px;  
                    margin-bottom: 30px;                  
                }       
            }
        }
    }
    .social-media{
        ul{
            li{
                padding-left:0px;
                padding-right:0px;
            }
        }
    }   
}