header{
    &.global-header{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        color: #FFFFFF;
        padding: 20px 0;   
        border-bottom: 1px solid rgba($white, 0.1);       

        .logo {            

            width: auto;
            display: flex;
            align-items: center;

            a {
                display: inline-flex;
                align-items: center;
                width: 100%;
                height: auto;
                max-width: 250px;
            }
        }

        .navbar-module{
            padding-top:20px;

            .top-cat{
                display: none;
                justify-content: end;                
                .cta-blue{
                    margin-left: 15px;
                    margin-right: 0;
                    width: auto;
                    letter-spacing: normal;
                }

                @include media(1024px, min) {
                    display: flex;
                }
            }
        }

        .global-header {
            &__inner {
                display: flex;
                align-items: center;

                .logo#logo-header {
                    flex: 1 0 auto;
                    padding-right: 1rem
                }

                @include media(1024px, min) {
                    flex: 0 0 350px;

                    .logo#logo-header {
                        padding-right: 0;
                        max-width: 200px;
                    }
                }
            }
        }

        @include media(1024px, min){
            .container {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }  
            .navbar-module{
                flex: 1;
                padding-top:0px;                
            }          
        }

        @include media(1170px, min){            
            .logo {                
                //padding-top: 20px;

                a {
                    max-width: 420px;
                }
            }
        }
    }
}
