// Home Page Banner Animation KeyFrames
@keyframes imageZoom {
    0% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.4);
      -moz-transform: scale(1.4);
      -ms-transform: scale(1.4);
      -o-transform: scale(1.4);
      transform: scale(1.4); 
    } 
}  
@-webkit-keyframes imageZoom {
    0% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1); 
    }
    100% {
      -webkit-transform: scale(1.4);
      -moz-transform: scale(1.4);
      -ms-transform: scale(1.4);
      -o-transform: scale(1.4);
      transform: scale(1.4); 
    } 
}