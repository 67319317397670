.mm-slideout{
    z-index: inherit;
}
.mobile-nav {
    .mm-title{
        img{
            float: left;
            width: 200px;
        }
    }
    .mm-panels {
        .mm-panel{
            width: 100%;        
        }
        .mm-subopened + .mm-opened{
            &:before {
                height: 30px; 
            }

            .mm-listview{
                padding: 0px 0px;
            }
        }
    }

    .desk-hide {
        a {
            font-weight: bold !important;
        }
    }
}



.mm-menu{background: $white;}

.mm-listview{
  li {
    color: $white; 
    font-weight: $regular; 
    @include font-size(16px, 16px);
    text-align: left;
    a{
        @include padding(20px);
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        @include font-size(16px, 16px);

        border: none !important;
    }
    &.active{
        a{
            color: $white;
        }
    } 
    img{
        display: none;
    }   
    &.has-sub{
        padding: 1em 0;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        a.mm-next{
            top: 15px;
            &::after{
                transform: rotate(225deg);
            }
        }
        &.mm-opened{
            & > a:not(.mm-next), & > a.mm-next::after{
                color: $primary-color !important;
                border-color: $primary-color;
            }
            a.mm-next{
                &::after{
                    transform: rotate(45deg);
                }
            }
        }
    }
  }
}
.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next), 
.mm-menu .mm-listview>li.mm-selected>span, 
.mm-menu.mm-theme-black .mm-listview>li.mm-opened.mm-vertical>a.mm-next {
    background: transparent !important;
    color: $primary-color !important;
}

.mm-menu.mm-theme-black .mm-listview>li.mm-opened.mm-vertical>.mm-panel{
    background-color: transparent !important;
}
.mob-nav{
    display: none !important;

    @include media(992px){
        display: block !important;
    }
}

// Mobile Navigation
.mobile-nav{
    background-color: $black;
    color: $white;

    .mm-panel.mm-hasnavbar .mm-navbar{display: none;}
    .mm-panels>.mm-panel.mm-hasnavbar{padding-top: 0;}

    .mm-panels>.mm-panel{
        @include padding(0px);
    }
    
    ul.l0{
        line-height: 1.3em;
        &.mm-listview{
            @include margin(0px);
        }

        &,li{
            width: 100%;
            color: $white;
        }

        li:after{display: none;}        
        
        li a{
            padding: 20px;
            display: block;
            font-size: 18px;
            color: $white;
            font-weight: $regular;
            text-decoration: none;
        }

        .emphasis{background: none;}
    }

    ul.l0.sub-menu li a{
        color: lighten($black, 25%);
    }

    .mm-menu .mm-btn:after {
        &.mm-menu .mm-btn:before{
            border-color: rgba(255,255,255,1);
        }
    }   
}

// Add New Feature Mobile Navigation css

.mm-navbar{
    .mm-btn{
        width: 50px;
        @include font-size(16px, 16px);

        &:last-child{
            text-align: left; right: 10px; top: 50%;
            @include vertical-align;
        }
    }

    &.mm-hasbtns {
        padding: 0 20px;
    }
}
.mm-navbars-top {
    border-bottom-width: 0px;
    @include padding-all(10px, 0px, 10px, 0px);
    background-color: darken($dark-blue, 5);
    .mm-prev.mm-btn{
        display: none !important;
    }
}
.mm-sronly {
    clip: inherit !important;
    -webkit-clip-path: inherit !important;
    overflow: inherit !important;  
    text-transform: uppercase;     
    text-indent: -99999px;
}

.mm-menu.mm-theme-black{
    background:  darken($dark-blue, 5);

    .mm-btn{
        &:after{
            border-color: $white;
        }
        &:before{
            border-color: $white;
        }
    }
}

.mm-menu{
    &.mm-theme-black{
        .mm-navbar{
            a{
                color: $white;
            }
        }
    }
}
.mm-menu{
    &.mm-theme-black{
        .mm-navbar{
            a{
                color: $white;
                @include font-size(20, 16);
                font-weight: $sum-med;
            }
            >*{
                color: $white;
            }
        }        
    }
}

.mm-clear{
    &:after{
        width: 12px; height: 10px; right: 18px; top: -2px;        
    }
    &:before{
        width: 10px; height: 10px; right: 6px; top: -2px;
    }
}
.mm-close{
    &:after{
        width: 14px; height: 14px; right: 18px; top: -2px;       
    }
    &:before{
        width: 14px; height: 14px; right: 0px; top: -2px;
    }
}

.mob-logo{
    //display: inline-block;
    float: left; padding: 20px 0;

    img{
        max-width: 80px;
    }
}

.mm-menu.mm-theme-black{
    .mm-listview{
        background:  darken($dark-blue, 5);
        @include box-shadow(0 6px 30px -10px rgba(#000, 0));
        width: 100% !important;

        li{
            a{
                padding:10px 0 !important;
                color: $white !important;
                text-transform: uppercase;

                &:hover{
                    background: rgba(255,255,255,0) !important;
                    color:$white !important;
                }              
            }

            &:last-child{   
                padding: 0;             
                a{
                    padding-bottom: 15px !important;                
                }
            }
            .border-cta{
                border-color: $white;
                margin: 20px 15px;
            }
        }
    }
}

.mm-panels{
    .mm-panel{
        .mm-navbar{margin-top: 0px;}
        .mm-listview{margin: 20px 0px;}
    }
}

.mm-panels>.mm-panel>.mm-navbar+.mm-listview{
    margin-top: 0px;
    padding: 0 20px;
}


.mm-menu.mm-theme-black .mm-listview>li .mm-next:after {
    //border-color: $white;
    width: 10px;
    height: 10px;
}

.mm-menu.mm-theme-black .mm-listview>li .mm-next:before {
    display: none;
}

.mm-menu{
    .nav-dropdown--primary{
        padding: 0 20px;
    }
    
    .featured{
        header{
            margin-bottom: 1em;
            padding-bottom: 1em;
            border-bottom: 1px solid $white;
            color: rgba(#fff, 0.5);
            text-transform: uppercase;
            font-size: 12px;
        }
    
        .nav-dropdown--secondary{
            margin-top: 0 !important;
            p{ display:  none !important;}
            li{
                padding: 5px;
                padding-left: 1em;
                a{
                    padding: 0.3em 0 !important;
                    text-transform: none !important;
                    font-size: 14px;
                }
                .cta-blue{
                    display: inline-block;
                    text-transform: uppercase !important;

                    width: auto;
                    margin-top: 2em;
                    padding: 1rem 2rem !important;
                    padding-right: 3.5rem !important;
                    color: white;
                    font-weight: bold;
                    &::before {
                        content: "\f08e";
                        @extend .far;
                        display: inline;
                        width: auto;
                        position: absolute;
                        right: 2rem;
                        top: calc(50% - 0px);
                        transform: translateY(-50%);
                        @include font-size(14px, 16px); 
                        color: $white;
                    }
                }
                &:last-of-type{
                    &::before{display: none !important;}
                }
            }
            li::before{
                content: "\f054";
                @extend .far;
                display: inline;
                width: auto;
                position: absolute;
                left: 0;
                top: calc(50% - 0px);
                transform: translateY(-50%);
                @include font-size(14px, 16px); 
                color: $primary-color;
            }
        }
        .mm-panel{
            display: block !important;
            padding: 10px 0 !important;
        }
        .mm-next{
            display: none !important;
        }
    }

    &.mm-theme-black .mm-listview li.featured > a.top-level{
        display: inline-block !important;
        position: relative;
        padding-right: 1.5em !important;
        padding-bottom: 0 !important;
        width: auto;
        text-transform: none !important;
        &::before {
            content: "\f08e";
            @extend .far;
            display: inline;
            width: auto;
            position: absolute;
            right: 0;
            top: calc(50% - -5px);
            transform: translateY(-50%);
            @include font-size(14px, 16px); 
            color: $primary-color;
        }
    }
    
    .featured > p {
        display: none !important;
    }
    
    
    .has-btn{
        float: left;
        margin: 3em 0.5em !important;
        width: calc(50% - 1em);
    }

    &.mm-theme-black .mm-listview li.has-btn a.cta-orange{
        color:  darken($dark-blue, 10) !important;
    }
}

