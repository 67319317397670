// Home Banner
$bgColor: #000114;

section{
    &.home-banner {
        color: $white;                
        background-color: $bgColor;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        min-height: 475px;

        .background-image {            
            background-color: $bgColor;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;        
            opacity: 0;
            @include transition(opacity, .25s);

            img{         
                display: none;       
                right: 0;
                @include vertical-align;                
                max-height: 100%;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            img.mobile-img {
                display: block;
                object-fit: fill;
                transform: none;
                bottom: 0;
                top: unset;
                width: 100%;
                height: auto;
            }

            &.loaded {
                opacity: 1;
            }

            @include media(768px, min) {

                overflow: hidden;
                -webkit-animation: imageZoom 30s linear 0s infinite alternate;
                animation: imageZoom 30s linear 0s infinite alternate;

                img.mobile-img {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }

        .banner-slider{

            display: flex;
            flex-direction: column;       
            justify-content: center;     
            padding-top: 125px;
            padding-bottom: 75px;
            flex: 1;    
            @include transition(opacity, .175s);

            .flickity-slider {
                display: flex;
                align-items: center;
            }

            &__track {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center ;
            }
 
            &__item {

                width: 100%;                            

                h1 {
                    line-height: 1.175;
                    margin-bottom: 0;
                }
                
                h1,p {
                    color: $white;
                }

                p {
                    padding-top: 1rem;
                    font-size: 1.35rem;
                    margin: 0;

                    @include media(768px, min) {
                        padding-top: 1.25rem;
                        font-size: 1.5rem;
                    }
                }

                .banner-item {
                    &__actions {
                        
                        padding-top: 2rem;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: 15px;

                        a,button {

                            padding-left: 60px;
                            padding-right: 60px;
                            margin: 0 !important;
                            
                            &.video-cta {                                

                                &::before {
                                    content: "\f04b";
                                    @extend .fas;
                                    position: absolute;
                                    left: 35px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    font-size: 1rem;
                                }
                            }         
                        }                        

                        @include media(768px, min) {
                            padding-top: 2.5rem;                                                        
                        }
                    }
                }
            }

            &__progress {
                max-width: 900px;
                width: 100%;
                margin: 0 auto;
                padding-top: 60px;
                padding-left: 15px;
                padding-right: 15px;

                ul {
                    display: flex;
                    list-style: none;
                    padding-left: 0;

                    > li {
                        flex: 0 0 50%;
                        text-align: center;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: .875rem;
                        @include transition(color, .2s);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.active {
                            color: $primary-color;
                        }
                    }

                    @include media(767px) {
                        display: none;
                    }
                }

                .slider-progress-bar-grid {
                    width: 100% !important;
                    background: $white;

                    .slider-progress-bar {                    
                        width: 50%;
                        background: $primary-color;
                        position: absolute;
                        top: 0;
                        left: 0;
                        @include transition(left, .4s);

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            &:not(.ready) {
                opacity: 0;

                .banner-slider__item:not(:first-child) {
                    position: absolute;
                }
            }

            @include media(1024px, min) {
                padding-top: 200px;
            }
        }        

        @include media(768px, min) {
            min-height: 100vh;
        }
    } 

    &.inner-banner {
        min-height: 450px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background: $bgColor;

        figure{
            position: unset;

            img {
                display: none;
                opacity: 0;
            }
            
            img.mobile-img {
                display: block;                            
            }

            &.loaded {
                img {
                    opacity: 1;
                }
            }

            @include media(768px, min) {
                img.mobile-img {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }

        .banner-intro{
            padding: 100px 0 50px 0;
            flex: 1;
            display: flex;
            align-items: center;

            h1,
            h2{
                color: $white;
                line-height: 1.1;
                font-weight: $regular;
                @include font-size(45px, 16px);
            }
            .cta-blue,
            .cta-orange{
                margin: 20px 10px 0 0;                
                width: 180px;               
            }

            .banner-date{
                color: $white;
                line-height: 1.1;
                font-weight: $regular;
                @include font-size(18px, 16px);

                span{
                    color: $primary-color;
                }
            }
        }
    }

    @include media(768px, min){
        &.inner-banner {

            min-height: 600px;

            .banner-intro{
                padding: 175px 0 75px 0;

                h1,
                h2{
                    @include font-size(65px, 16px);
                }
    
                .cta-blue,
                .cta-orange{
                    margin: 30px 15px 0 0;                
                    width: 238px;                    
                }
            }
        }        
    }
}


