.story-module{    
    .container{
        .inner{
            padding-left: 0px;
            padding-right: 0px;
        }

        header{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: auto;
            z-index: 2;
            //justify-content: space-between; 
            //bottom: -48px;
    
            h2{
                margin-bottom: 0px;
            }
            p{
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            .cta-blue-border{
                &.right{
                    margin-left: auto;
                }
                margin-right: 0;                
            }

            & + .groupSlider{
                top:-42px;
                margin-bottom: -32px;
            }
        } 
    }
    .groupSlider{
        padding: 16*$mar 0 10*$mar;
        margin: 0px;        
                    
        .slider {
            width: calc(90% - 1rem);
            padding: 0 16px 0 0;            
        }

        &.story-slider{
            a{
                color:$text-color; 
                &:hover{
                    h6{
                        color: $secondary-color;
                    }
                }
            }
        }
    }     

    @include media (580px, min){  
        .groupSlider{ 
            .slider {
                width: calc(50% - 1rem);                
            }
        }
    }    
    @include media (768px, min){ 
        .groupSlider{  
            max-width: 900px;        
            .slider {
                width: calc(40% - 1rem);                
            }
        }

        .container{
            overflow: hidden;

            .inner{
                padding-left: 15px;
                padding-right: 15px;
            }
            header{
                margin-right: 120px;
                .cta-blue-border{                    
                    &.right{
                        margin-left: auto;
                    }
                }
            }
        }
    }
    @include media (1280px, min){
        .groupSlider{
            width: 100vw;
            max-width: 1045px;   
            
            .slider {
                width: calc(31% - 1rem);                
            }                         
        }
        .container{
            header{
                width: 100vw;
                max-width: 915px;               
            }
        }
        .slider-progress-bar-grid{            
            width: 100vw;
            max-width: 1045px; 
        }         
    }    
}

.group-btn{
    [class*=cta-]{
        margin-top: 50px;
    }
}