// PAGE FORM
.checkbox-list input[type=file]:focus, 
.checkbox-list input[type=checkbox]:focus, 
.checkbox-list input[type=radio]:focus {outline: none; outline-offset: 0;}

.field {
    margin-bottom: 5*$mar;
    @include selfClearing;

    label{
        display: block;
        width: 100%;
        margin-bottom:2*$mar;
        @include font-size(16px, 16px);
        color:$text-color;
    }

    .error{        
        border-color: $red !important;        
        &+.error{        
            color: $red;
            font-weight: $regular !important;
            border-color: $trans !important;
            font-size: 14px !important; 
            margin:$mar 0 0;
        }
    }
}

.form-control,
.form-select{
    display: block;
    width: 100%;    
    padding: 8px 2*$pad;
    line-height: 1.5;
    color: $text-color;
    background-color: $w;
    border: 1px solid $grey;    
    outline: none;

    &:focus {        
        outline: none;
    }    
}

/* Checkbox Css Style */
.form-check{
    cursor: pointer;  
    position: relative; 
    padding: 0 0 0 30px; 
    margin-bottom:0px !important;    
    font-weight:$light;
    width:100%;
    display:block;
    @include font-size(16px, 16px);

    .error{
        border-color: $red !important;        
        &+label{        
            display:none !important;
        }
    }

    .checkbox{
        -webkit-appearance: none; -moz-appearance: none; 
        -ms-appearance: none; -o-appearance: none; 
        appearance: none; position: absolute; 
        top: 3px; right: 0; bottom: 0; left: 0;
        z-index: 9;         
        height: 16px; width: 16px;         
        transition: all 0.15s ease-out 0s;    
        display: inline-block; 
        outline: none; 
        cursor: pointer; 
        background: $w; 
        border: 1px solid $grey;

        &:checked{
            background: $primary-color; 
            border: 1px solid $primary-color; 

            &:before {
                content: '✔'; 
                height:16px; 
                width: 16px; 
                line-height: 16px; 
                position: absolute; 
                display: inline-block; 
                font-size: 12px; 
                text-align: center;                
                color:$white;
            }
        }
    }
    a{   
        color:$primary-color;        
        &:hover{
            color:$secondary-color;       
        }
    }

    &.inline{
        display:inline-block;
        width:auto; 
        margin-right:4*$mar;
        font-weight:$regular;
        @include font-size(16px, 16px);
    } 
    
    &.larg{
        font-size: 20px !important;
        font-weight:$med !important;

        .checkbox{            
            top: 5px;            
            height: 20px; width: 20px;    
            &:checked{               
    
                &:before {                    
                    height:20px; 
                    width: 20px; 
                    line-height: 20px;                     
                    font-size: 14px;                     
                }
            }
        }
    }
}

.form-radio {
    @extend .form-check;
    padding: $pad 0 $pad 7*$pad;   
    
    .radiobox{
        -webkit-appearance: none; -moz-appearance: none; 
        -ms-appearance: none; -o-appearance: none; 
        appearance: none; position: absolute; 
        top: 4px; right: 0; bottom: 0; left: 0; z-index: 9; 
        @include border-radius(50%);
        height: 22px; width: 22px; 
        border: 2px solid #ebebeb; 
        transition: all 0.15s ease-out 0s;    
        display: inline-block; 
        outline: none; 
        cursor: pointer; 
        background: $w; 
        border: 2px solid $ddgrey;
        background-color:$w;
        @include box-shadow(0px 3px 5px 0px rgba($black, 0.4)); 

        &:checked{
            &:before {
                content: ''; 
                height:10px; 
                width: 10px; 
                display: inline-block;
                @include border-radius(50%);                
                @include middle; 
                background: $green;
            }
        }
    }
}
/* End Css */

textarea.form-control {
    height: auto;
}

.input-group {
    &.checkbox {

        flex: 0 0 100% !important;

        .checkbox-input {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            &__toggle {
                input[type=checkbox] {
                    appearance: none;
                    width: 19px;
                    height: 19px;
                    padding: 0 !important;
                    background: $white;
                    cursor: pointer;

                    &:checked {

                        border-color: $primary-color;

                        & ~ span {
                            opacity: 1;
                        }
                    }

                    &:focus {
                        outline: none;
                    }
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 1px);
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    pointer-events: none;

                    &::before {
                        content: "\f00c";
                        @extend .fas;
                        font-size: .75rem;
                        color: $primary-color;
                    }
                }
            }

            &__label {
                display: block;
                padding-left: .5rem;

                label {
                    cursor: pointer;

                    a {
                        text-decoration: underline;
                    }
                }

                @include media(768px, min) {
                    padding-left: 1rem;
                }
            }
        }   
    }

    &.submit {
        flex: 0 0 100% !important;

        button, a, input[type=submit] {
            width: 100%;
        }
    }
}

//Download form
.download-box {
    background: #ebebeb;
    padding: 1.25rem;

    .inner {
        max-width: 800px;
        display: block;

        &.success {
            display: none;

            .download-box {
                &__form {

                    padding: .5rem 0;

                    .close-download {
                        background: transparent;
                        min-width: min(200px, 100%);
                        padding: .75rem 1rem;
                    }
                }
            }
        }
    }

    &__header {

        padding: 0 0 1rem 0;

        h2 {
            color: $primary-color;
            font-weight: bold;
            font-size: 1.25rem;
            margin-bottom: 0;
        }

        p {
            margin: 0;
            padding-top: .75rem;
        }

        a {
            color: $primary-color;
            text-decoration: underline;
        }

        @include media(1024px, min) {
            padding-bottom: 1.5rem;
        }
    }

    &__form {
    
        form {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: -1.5rem;

            @include media(648px, min) {
                width: calc(100% + 1rem);
                margin-left: -.5rem;
            }
    
            @include media(1024px, min) {                
                width: calc(100% + 2rem);
                margin-left: -1rem;
            }
        }

        .umbraco-forms-page {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .input-group {
            flex: 0 0 100%;
            padding-top: 1.5rem;            

            label {
                display: block;
                padding-bottom: .75rem;
            }

            input, select {

                display: block;
                border-radius: 4px;
                border: solid 1px #a9a9a9;
                padding: .75rem 1rem;
                width: 100%;

                &:focus {
                    outline: solid 1px $primary-color;
                }
            }

            &.hidden {
                position: absolute;
                z-index: -1;
            }            

            @include media(648px, min) {
                flex: 0 0 50%;
                padding-left: .5rem;
                padding-right: .5rem;
            }

            @include media(1024px, min) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }    
        
        .umbraco-forms-navigation {
            width: 100%;
            padding-top: 1.5rem; 

            button, a, input[type=submit] {
                width: 100%;
                padding: 15px !important;                
            }
        }
    }

    &:not(.visible) {
        display: none;
    }

    @include media(1024px, min) {
        padding: 2rem
    }
}

.field-validation-error {
    display: block;
    font-size: .875rem;
    margin-top: .25rem;
    color: #c53030;
    font-weight: normal !important;
}

.umbraco-forms-tooltip.help-block {
    font-style: italic;
    display: block;
    padding-bottom: .5rem;
}