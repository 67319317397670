.home-intro-module{
    h1{
        margin-bottom: 8*$mar;
        line-height: 1.2;
    }
    h2{
        margin-bottom: 0;
    }  
    h5{
        line-height: 1.6;
    }  

    small{                
        margin-bottom: 2*$mar;        
    }
}
