.intro-module{
    h1,
    h2{
        margin-bottom: 6*$mar;
        line-height: 1.2;
        font-weight: $bold;
        @include font-size(30px, 16px);
    }

    figure{
        &.company-logo{
            max-width: 360px;
            margin: 0 0 40px;
        }
    }

    blockquote{
        border-top:3px solid $primary-color;
        border-bottom:3px solid $primary-color;
        padding: 5*$pad 0;
        margin: 8*$pad 0;
        font-weight: $bold;
        line-height: 1.4;
        @include font-size(20px, 16px);
    }

    a:not([class]) {
        color: $primary-color;
    }

    .cta-blue,
    .cta-orange{
        margin: 20px 20px 0 0;
        padding: 15px 24px;
        width: 230px;
        @include border-radius(25px);
    }

    .card-text-icon{
        padding: 5*$pad;
        
        figure{
            width:91px;
            height: 91px;                       
        }
        header{
            margin:9*$mar 0 2*$mar;  
            min-height: 60px; 
            
            h4{
                margin-bottom: 0px;
            }
        }              
    }

    @include media(768px, min){
        h1,
        h2{           
            @include font-size(38px, 16px);
        }
        h3{
            @include font-size(30px, 16px); 
        }  
    }
}


.card-module{
    @extend .intro-module;
}


.text-module{
    @extend .intro-module;
}