.media-text-module{

    .inner {
        .row-col.gbl-80 {

            margin-top: 0;
            margin-bottom: 0;

            & + .row-col.gbl-80 {
                margin-top: 50px;

                @include media(768px, min) {
                    margin-top: 80px;
                }
            }
        }
    }

    h2{
        line-height: 1.2;
    }    

    figure{
        margin-bottom:20px; 
    }

    main {

        p {
            margin-bottom: calc(20px - .5rem);
        }

        a {
            margin: .5rem 0;
        }
    }
    
    ul{
        margin: 20px 0;
        li{
            @include font-size(18px, 16px);
            margin: 14px 0;
        }
    }
      

    &.with-partner-text-logo{
        figure{
            border:1px solid $grey;
            overflow: hidden;
            @include border-radius(10px);
            margin: 2*$mar 0;
            max-width: 200px;
        }
        main{
            margin: 2*$mar 0;
            h4{
                margin-bottom:2*$mar;
            }
            p{
                margin-top: 0;
            }
        }
    }

    @include media(768px, min){
        h3{
            margin-bottom: 6*$mar;            
        } 
        figure{
            margin-right:30px;
            margin-bottom:0px;  
        }

        &.with-partnerLogo{            
            main{
                margin: 2*$mar 0 0 6*$mar;                
            }
        }
    }  
}



