.loader{
    img{
        width: 50px;
        height: 50px;
        display: inline-block;
    }
}

.loadmore-wrapper {
    display: block;
    padding-top: 50px;
    text-align: center;
}