.events-slider-module{    
  @extend .team-slider-module; 

  .groupSlider {
    .cards {
      &:hover {
        color: unset;
        text-decoration: unset;
      }
    }
  }
}

.events-module{ 

  .list-grid-3{
    justify-content:left; 
    
    li{
      padding-bottom: 2*$mar;
    }
  } 

  .event-date-time{
    ul{
      @extend %listless;      

      li{
        margin: 0 0 30px;
        float: none;

        h6{
          color: $primary-color;
          font-weight: $bold;
          margin-bottom: 10px;
        }
        p{
          margin: 0px;
          line-height: 1.2;
          font-weight: $med;
        }
      }
    }
  }

  @include media(768px, min){
    .list-grid-3{   
      li{
        .cards{
          .card-event{
            padding-bottom: 8*$mar;
          }
        }
      }
    }

    .event-date-time{
      ul{
        li{
          float: left;
          width: 33.333%;
          height: 100px;
        }
      }
    }
  }  

  @include media(992px, min){
    .event-date-time{
      ul{        
        margin-left: 50px;  
        
        li{
          float: left;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
  

