// Button Stylings for Cookies Noti bar

[class*=cta-]{    
	display: inline-block;
    text-align: center;
    text-decoration: none; 
    position: relative; 
    border: 0px; 
    outline: none;
    cursor: pointer;    
    padding: 15px 24px; 
    margin-right: 15px;
    @include border-radius(25px);
    @include font-size(14px, 16px);
    font-weight: $bold;
    letter-spacing: 1px;
    white-space: nowrap; 
    text-transform: uppercase;
    
    &.mt-10{margin-top:2*$mar;}
    &.mt-20{margin-top:4*$mar;}
    &.mt-30{margin-top:6*$mar;}
    &.mt-40{margin-top:8*$mar;}
    &.mt-50{margin-top:10*$mar;}

    &.with-icon{
        i{
            position: relative;
            top: 3px;
            left: 0;
            transform: translateX(-10px);
            transition: transform 250ms;
        }
    }

    &.small{
        width: 100px !important;
        padding: 10px;
        margin-right:0px !important; 
    }

    @include media(768px, min){
        &.with-icon{
            i{                
                transform: translateX(-20px);                
            }
        }
    }
}

.cta-blue{   
    background:$primary-color;    
    color:$white;   
    
    &:hover{
        background:$secondary-color;
        color:$w;       
    }
}
.cta-orange{   
    background:$secondary-color;
    color:$white;    

    &:hover{
        background:$primary-color;
        color:$w;  
        
        &.with-icon{
            i{                
                transform: translateX(-10px);
            }
        }
    }
}

.cta-blue-border{   
    background:$white;
    border:1px solid $primary-color;     
    color:$primary-color;
    padding: 12px 24px; 
    
    &:hover{
        border:1px solid $secondary-color;
        color:$secondary-color;       
    }
}

.txt-blue{   
    color:$primary-color;    
    
    &:hover{
        color:$secondary-color;       
    }
}

.arrow-r {
    display: inline-block;
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;    
    border-left: 10px solid $white;
}

@include media(768px, min){
    .cta-blue,
    .cta-orange{
        width: 238px;
    }
    
}




 

