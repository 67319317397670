.fliter-grid{
    display: flex;
    align-items: center;

    .filter-title{
        margin-right: 40px;
        margin-bottom: 10px;
    }
    .filter-item{
        flex: 1;

        ul{
            @extend %listless;
            li{
                margin-right:20px;
                
                .field {
                    margin-bottom: 10px;
                    .checkbox{
                        top: 1px !important;
                    }
                }
            }
        }
    }
}