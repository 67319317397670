.testimonial-module{
    .inner{
        overflow: hidden;
    }
    .testimonial-slideSet{        
        margin: 0 -15px;

        .block{
            padding: 0 15px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            @include flex-width(100%);                    

            figure{
                margin: 0 0 30px 0;
            }
            main{
                margin-bottom: 30px;                
            }
            footer{                
                @include font-size(12px, 16px);
                font-weight: $bold;
                //min-height: 30px;
                text-transform: uppercase;
            }
        } 
        
        @include media(768px, min){ 
            .block{
                @include flex-width(50%); 
                
                main{
                    margin-bottom: 20px;
                    min-height: 140px;
                }
            }
        }

        @include media(992px, min){ 
            .block{
                @include flex-width(33.3333%);                
            } 
        }
    }
}