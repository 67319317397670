section.demo-video-module{
    .video-block{
        padding-top:56.66%;
        img{
            border-radius: 20px;
        }
    }
    .trigger-video {
        position: absolute;
        top: calc(50% - 50px);
        left: 50%;
        transform: translateX(-50%);
        width: 75px;
        height: 75px;
        border-radius: 50%;
        background: $primary-color;
        border: none;

        &::before {
            content: "\f04b";
            @extend .fas;
            position: absolute;
            top: 50%;
            left: calc(50% + 1px);
            transform: translate(-50%, -50%);
            color: $white;
            font-size: 24px;
        }
    }
}

.inline-video-wrapper{
    iframe{
        width: 100%;
        height: auto;
    }
}