.vacancy-module{
    
    main{
        .table-responsive {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
        tbody, td, tfoot, th, thead, tr {
            border-color: inherit;
            border-style: solid;
            border-width: 0;
        }
        .table {            
            width: 100%;
            margin-bottom: 4*$mar;           
            vertical-align: top;
            caption-side: bottom;
            border-collapse: collapse;
            border-color: #dee2e6;
            display: table;

            &>:not(caption)>*>* {
                padding: 4*$pad 2*$pad;
                border-bottom-width: 1px;
                       
            }

            &>thead {
                vertical-align: bottom;
                background: $primary-color;
                color: $white;
                text-align: left;     
            } 
            &>table {
                caption-side: bottom;
                border-collapse: collapse;
            }
            &>tr {
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
            }       
        }
        
        
    }

    footer{
        a{
            color:$primary-color
        }
    }
}