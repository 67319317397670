.logo-module{
    main{
        .logoSlider{            
            .logo-item{
                padding:0 6*$pad;
                margin: 2*$mar 0 0;            
                text-align: center;                 
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100px;
            }
        }       
        
    }

    &.with-partner-logo{
        
        .logoSlider{  
            margin: 0 -8px;            
                      
            .logo-item{
                width: 50%;
                padding: 0 8px;
                height: auto;
                flex-direction: row;
                flex-wrap: wrap;

                figure{
                    border:1px solid $grey;
                    overflow: hidden;
                    @include border-radius(10px);
                    margin: 2*$mar 0;
                    max-width: 200px;
                    flex: 0 0 100%;
                }

                p {
                    flex: 0 0 100%;
                    margin: 0 !important;
                    @include font-size(14px, 16px);
                }

                @include media(575px, min) {
                    width: 33.33%;
                }

                @include media(768px, min) {
                    width: 25%;
                }
            }
        } 
    }
}