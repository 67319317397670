.form-module {
    header {
        margin-bottom: 50px;

        h1,
        h2 {
            margin-bottom: 6 * $mar;
            line-height: 1.2;
            font-weight: $bold;
            @include font-size(30px, 16px);
        }

        a:not([class]) {
            color: $primary-color;
            text-decoration: none !important;
            font-weight: bold;

            * {
                text-decoration: inherit !important;
            }

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
    main {
        .contac-info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0 35px;
            @extend %listless;
            margin-bottom: 30px;
            float: none !important;

            li {
                float: none !important;
                margin-right: 40px;
                font-weight: $bold;
                @include font-size(18px, 16px);

                a {
                    color: $primary-color;

                    &:hover {
                        color: $secondary-color;
                    }
                }

                p {
                    margin: 0 0 5px;
                }
            }
        }

        .contact-form {
            width: 100%;
            @include selfClearing;

            .field {
                label {
                    text-transform: uppercase;
                    font-weight: $sum-med;

                    span {
                        color: $red;
                        line-height: 10px;
                        @include font-size(22px, 16px);
                    }
                }

                .form-check {
                    @include font-size(14px, 16px);
                    text-transform: inherit;
                    float: right;
                    width: auto;

                    .checkbox {
                        top: 0px;
                    }
                }

                .cta-blue-border {
                    min-width: 150px;
                }
            }
        }

        .umbraco-forms-form {
            padding: 0;

            .umbraco-forms-page {
                fieldset {
                    padding: 0;
                    margin: 0;
                    border: none !important;
                }
                .row-fluid {
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin-left: -15px;
                    margin-right: -15px;

                    .col-md-6 {
                        @include flex-width(100%);
                        padding-left: 15px;
                        padding-right: 15px;

                        &.umbraco-forms-container {
                            .cta-blue-border {
                                float: right;
                                margin-right: 0px;
                            }
                        }

                        .umbraco-forms-field {
                            margin-bottom: 25px;

                            .umbraco-forms-label {
                                text-transform: uppercase;
                                font-weight: $sum-med;
                                display: block;
                                width: 100%;
                                margin-bottom: 10px;
                                @include font-size(16px, 16px);
                            }
                            .umbraco-forms-field-wrapper {
                                input[type='text'],
                                textarea,
                                select {
                                    display: block;
                                    width: 100%;
                                    padding: 8px 10px;
                                    line-height: 1.5;
                                    color: #000;
                                    background-color: #fff;
                                    border: 1px solid #cecece;
                                    outline: none;
                                }
                            }

                            &.dataconsent {
                                @include font-size(14px, 16px);
                                font-weight: $sum-med;
                                text-align: right;

                                label {
                                    top: -2px;
                                    position: relative;
                                }

                                a {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media(768px, min) {
        header {
            h1,
            h2 {
                @include font-size(38px, 16px);
            }
        }

        main {
            .umbraco-forms-form {
                .umbraco-forms-page {
                    .row-fluid {
                        .col-md-6 {
                            @include flex-width(50%);
                        }
                    }
                }
            }
        }
    }

    &--refreshed {
        .inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 2rem;

            .form-module {
                &__info {
                    flex: 0 0 100%;

                    > h2 {
                        margin: 0 !important;
                        padding-bottom: 1.5rem;

                        strong {
                            color: $primary-color;
                        }
                    }

                    > p {
                        margin: 0 !important;
                        padding-bottom: 1.5rem;
                        font-weight: 500;
                    }

                    .hotlinks {
                        border-bottom: solid 1px $black;
                        padding-bottom: 1.5rem;
                        margin-bottom: 1.5rem;

                        li a span {
                            color: $black;
                            font-weight: 500;
                        }
                    }

                    .footnote {
                        > p {
                            font-size: 1.25rem;
                            font-weight: 700;
                            line-height: 140%;
                        }

                        > a.cta-blue-border {
                            margin-top: 1.5rem;
                            font-size: 1.125rem;
                            text-transform: none;
                            font-weight: 500;
                            color: $black;
                            letter-spacing: normal;
                            padding: .75rem 1.5rem;
                        }
                    }

                    &.desktop-hide {
                        .hotlinks {
                            border-top: solid 1px $black;
                            padding: 1.5rem 0;
                        }
                    }
                }

                &__fields {
                    flex: 0 0 100%;

                    .umbraco-forms-page {
                        display: flex;
                        flex-direction: column;
                        row-gap: 1.5rem;
                    }

                    fieldset {
                        .row-fluid {
                            display: block;
                            margin: 0 !important;

                            > .umbraco-forms-container {
                                display: flex;
                                flex-direction: column;
                                margin-left: 0;
                                margin-right: 0;
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                                row-gap: 1.5rem;
                            }
                        }

                        &:first-child {
                            .row-fluid {
                                > .umbraco-forms-container {
                                    display: flex;
                                    flex-direction: row !important;
                                    flex-wrap: wrap;
                                    width: calc(100% + 1rem);
                                    margin-left: -0.5rem !important;

                                    .umbraco-forms-field {
                                        padding: 0 0.5rem;
                                        flex: 0 0 100%;
                                        align-self: flex-end;

                                        &:nth-of-type(2) {
                                            .umbraco-forms-label {
                                                display: none;
                                            }
                                        }

                                        @media screen and (min-width: 768px) {
                                            flex: 0 0 50%;
                                        }
                                    }
                                }
                            }
                        }

                        label.umbraco-forms-label {
                            display: block;
                            padding-bottom: 0.625rem;
                            color: $black;
                            font-size: 1rem;

                            @media screen and (min-width: 768px) {
                                font-size: 1.125rem;
                            }
                        }

                        .umbraco-forms-field-wrapper {
                            input:not([type='checkbox']),
                            select,
                            textarea {
                                width: 100%;
                                max-width: 100% !important;
                                border-radius: 5px;
                                padding: 0.625rem 1.25rem;
                                appearance: none;
                                border: solid 1px $black;
                                background: $white;
                            }

                            textarea {
                                min-height: 125px;
                                resize: vertical;
                            }

                            label {
                                a {
                                    color: $primary-color;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .umbraco-forms-tooltip.help-block {
                        font-style: normal;
                        font-size: 0.875rem;

                        & + .umbraco-forms-field-wrapper {
                            margin-top: 1.5rem;
                        }
                    }

                    .umbraco-forms-navigation {

                        margin: 0 !important;

                        .col-md-12 {

                            flex: 0 0 100%;

                            input.cta-blue {
                                width: 100% !important;

                            }
                        }
                    }
                }

                @media screen and (min-width: 1024px) {
                    &__info {
                        flex: 0 0 40%;
                        padding-right: 1.75rem;
                    }

                    &__fields {
                        flex: 1;
                        padding-left: 1.75rem;
                    }
                }

                @media screen and (min-width: 1440px) {
                    &__info {
                        padding-right: 2.5rem;
                    }

                    &__fields {
                        padding-left: 2.5rem;
                    }
                }
            }

            .mobile-hide {
                @media screen and (max-width: 1023px) {
                    display: none;
                }
            }

            .desktop-hide {
                @media screen and (min-width: 1024px) {
                    display: none;
                }
            }
        }
    }
}
