.slideSet{
    .slider {
        width: 100%;
        margin-right: 0px;     
        //counter-increment: slider;
    }    

    .flickity-prev-next-button{
        width: 40px;
        height: 40px;
        border-radius: 0;
        background: rgba(0,0,0,0);
        @include padding(0px);

        svg{
            display: none !important;
        }
    }

    .flickity-prev-next-button,
        .flickity-prev-next-button {                
        border: 1px solid $primary-color;    
        @include border-radius(50%);
        
        &:after {
            color: $primary-color; 
            @include font-size(24px, 16px);
            font-family: 'Font Awesome 5 Pro';
            @include middle;            
        }   
        &:focus {
            border: 0px;
            outline: none;
        }         
    }
    .flickity-prev-next-button.previous {
        left: 0px;
        &:after{           
            content: "\f104";            
        }
    }
    .flickity-prev-next-button.next{
        right: 0px;
        &:after{
            content: "\f105";            
        }
    }    
}

.groupSlider{
    @extend .slideSet;
    z-index:1;
    margin: 0 -10px;

    .slider {
        width: calc(90% - 1rem);
        padding: 0 10px;         
    }  

    .flickity-prev-next-button{
        display:none;
        top:25px;   
    }
    .flickity-prev-next-button.previous {
        left: inherit;
        right: 65px;        
    }
    .flickity-prev-next-button.next{
        right: 12px;  
    }

    @include media (768px, min){ 
        margin: 0 -24px;        
        .slider {
            width: calc(50%); 
            padding: 0 24px;                       
        }    
        .flickity-prev-next-button{
            display:inline-block;   
        }
    } 
    @media (min-width:992px){
        .slider {
            width: calc(33.333%);                       
        }
    } 
}


.slider-progress-bar-grid,
.slider-progress-bar-grid2{
    height: 3px;            
    position: relative;        
    overflow: hidden;        
    z-index: 9;            

    &:after {
        content: '';
        width: 100%;
        height: 2px;
        background: rgba($color: $b, $alpha: 0.2);     
        position: absolute;           
        bottom: 0px;
        z-index: 8;
    }
    .slider-progress-bar {
        height: 3px;
        width: 0;           
        background: $primary-color;           
        bottom: 0px;
        z-index: 9;
    }
} 














