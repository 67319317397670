// Bread Crumbs

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding:30px 0 0;
    list-style: none;

    .breadcrumb-item{
        text-transform: uppercase;
        font-weight: $bold;
        @include font-size(13px, 16px);
        a{
            color: $primary-color;   
            
            &:hover{
                text-decoration: underline;
            }
        }

        &+.breadcrumb-item{
            padding-left: 10px;
    
            &:before {
                float: left;
                padding-right: 0.5rem;
                color: #6c757d;
                content: "/";
            }
        }
    }    
}

