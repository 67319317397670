.team-slider-module{    
  @extend .story-module;
}


.our-team-module{
  .list-grid-4,
  .list-grid-2{
      justify-content:left;

      li{
          @include flex-width(50%);
          
          .card-team-info{
              figure {
                  height: 150px;                    
                  margin: 0px 0px 10px;                    
              }
      
              main{
                  min-height: 75px;
                  h4{
                      @include font-size(16px, 16px);
                  }
                  h6{                    
                      @include font-size(14px, 16px);                    
                  }            
              } 
              
              .social-media{
                  &>ul{                 
                      &>li{ 
                          padding: 0; 
                          @include flex-width(auto);
                          max-width: inherit;                                     
                      }
                  }
              }
          }
      }

      @include media(768px, min){          
          li{
              @include flex-width(33.333%);              
              padding-bottom:10*$pad;
          }  
      }        
      
      @include media(992px, min){        
          li{
              @include flex-width(25%);
          }              
      }
  }

  .list-grid-2{
      justify-content:left;

      li{
          @include flex-width(100%);            
      }
      @include media(768px, min){
          li{
              @include flex-width(100%);
          }  
      }        
      @include media(992px, min){        
          li{
              @include flex-width(50%);
          }              
      }    
      
      .list-grid-2{
          li{
              @include flex-width(50%);               
          }
          @include media(768px, min){
              li{
                  @include flex-width(33.333%); 
              }  
          }
          @include media(992px, min){        
              li{
                  @include flex-width(50%);
              }              
          }
      }
  }
}

