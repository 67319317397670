.global-footer{
    background-color: #010010;    
    background-size: 90%;
	background-position: center right;
	background-repeat: no-repeat;    
    padding: 10*$pad 0;    
    color:$white;

    .footer-top{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .newsletter-grid{
            width: 100%;
            margin-bottom: 5*$mar;

            &-inner {
                max-width: 540px;  
            }
            
            p{
                @include font-size(18px, 16px);
                margin-top: 0px;
            }

            form.newsletter{
                border: 1px solid $white;
                @include border-radius(25px);
                padding: 0px 15px 0 10px;
                position: relative;
                display: flex;
                align-items: center;

                .form-control{
                    flex: 1;
                    padding: 10px;
                    background: $trans;
                    color: $white;
                    border: 0px;
                    outline: none;
                }
                button{
                    background: $trans;
                    color: $primary-color;
                    border: 0;
                    outline: none;
                    @include font-size(24px, 16px);
                }
            }

            .umbraco-forms-tooltip.help-block {
                display: block;
                margin-top: .5rem;
            }

            //Umbraco newsletter
            .newsletter.umbraco-forms-newsletter {

                padding: 0;

                .umbraco-forms-field {

                    .umbraco-forms-field-wrapper {
                        position: relative;
                    }

                    input {
                        border: 1px solid $white;
                        @include border-radius(25px);
                        padding: 10px 50px 10px 20px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        background: transparent;
                        outline: none !important;
                        color: $white;
                        max-width: unset !important;
                        margin: 0 !important;
                        line-height: 1.5;
                        width: 100% !important;

                        &:focus {
                            outline: none !important;
                        }
                    }
                }

                .field-validation-error {
                    display: block;
                    margin-top: .25rem;
                }

                .umbraco-forms-navigation {
                    padding: 0 !important;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    
                    button {

                        appearance: none;
                        background: none;
                        border: none;
                        padding: 0;

                        &::after {
                            content: "\f30b";
                            @extend .fas;
                            color: $primary-color;
                            font-size: 1.5rem;
                            font-weight: 400 !important;
                        }
                    }
                }
            }

            @media screen and (min-width: 1024px) {
                padding-right: 15rem;
            }
        }

        .social-media{
            width: auto;
            display: flex;
            align-items: end;
            margin-bottom: 25px;
        }

        .footer-nav{         
            
            display: flex;
            width: 100%;

            ul{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                @extend %listless;
                margin-top: auto;
                height: auto;

                li{
                    margin: 3*$mar 5*$mar 0 0;
                    a{
                        color: $white;
                        text-transform: uppercase;

                        &:hover{
                            color: $primary-color;
                        }
                    }
                }
            }

            @media screen and (min-width: 1024px) {
                flex: 1;
                width: auto;
            }
        }
    }

    .footer-bottom{

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        row-gap: 2rem;
        column-gap: 2rem;
        margin-top: 10*$mar;

        .terms-link{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @extend %listless;

            li{
                margin: 0 3*$mar 4*$mar 0 ;
                a{
                    color: $white;                    
                    @include font-size(12px, 16px);

                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }

        p{
            @include font-size(12px, 16px);
            color: $mgrey;
            margin-bottom: 0px;
        }
    }

    .social-media{
        &>ul{    
            li{ 
                margin:0 0 0 12px;                            
                &>a{                    
                    color: $white;
                    width: 44px;
                    height: 44px;
                    border:2px solid $white;
                       
                    &:hover,
                    &.active{
                        color: $primary-color; 
                        border:2px solid $primary-color; 
                    } 
                }            
            }
        }             
    }

    @include media(992px, min){
        .footer-top{
            .footer-nav{
                ul{
                    li{
                        margin: 6*$mar 10*$mar 0 0;
                    }
                }
            }
        }

        .footer-bottom{
            margin-top: 20*$mar;
        }
    }

    @include media(991px, max) {
        background-image: none !important;
    }
}