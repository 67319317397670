.list-item{
    ul{
        @include padding(0px);
        @include margin(0px);
        list-style: none;

        li{
            @include margin-all(0px, 0px, 10px, 0px);
            @include padding(0px);
            position: relative;           
        }
    }    
}

.tick-list{
    @extend .list-item;
    ul{
        li{
            padding-left:35px;
            @extend .fa-check;
            
            &::before{
                @extend .fas; 
                position:absolute;
                left:0px;
                top:5px; 
                color:$primary-color;
                @include font-size(18px, 16px);              
            }             
        }
    }
}



