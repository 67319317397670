header.siteHeader{
    background-color: $black;

    // GLOBAL NAVIGATION
    nav.nav{        
        font-family: $Montserrat;
        @include selfClearing;
        float: right;
        display: none; 

        a{
            text-decoration: none; 
            display: inline-block;
        }

        [class^="cta-"] {
            margin: 0;
            margin-top: -0.8rem;
            padding: 0.8rem 1.5rem;
            width: auto;
            &:hover, &:active, &:focus{
                color: $white;
            }
        }

        > ul{
            @extend %listless; 
            width: auto;        
            
            > li {
                padding:14px 18px;

                &:last-child {
                    padding-right: 0;
                }
            }
            
            li {        
                position: relative;   
                letter-spacing: 1;             

               &>a{                    
                    display:block; 
                    position: relative;
                    color: $white;                    
                    @include font-size(14px, 16px);
                    margin: 0;                   
                    text-align: center;
                    text-transform: uppercase;
                    font-weight: $bold;
                    padding-bottom: .5rem;
                    letter-spacing: 1px;
                    //padding: 0.8rem 0;

                    &.active{
                        color: $primary-color;                        
                    }
                }
                &:last-child{

                    &:hover .sub-menu{
                        left:inherit;
                        right:0px;
                    }
                }
                &:hover .sub-menu{
                    visibility: visible;
                    opacity: 1;                    
                }
                &:hover > a{
                    color: $primary-color;                    
                }  
                
                &.has-sub {
                    border-radius: 10px;
                    &::before{
                        content:"";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 96%;
                        background-color: $black;
                        border-radius: 6px;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: -20px;
                        left: 0;
                        width: 100%;
                        height: 20px;
                        background-color: transparent;
                    }
                    // > a::after {
                    //     content: "";
                    //     position: absolute;
                    //     bottom: 0;
                    //     left: 0;
                    //     width: 100%;
                    //     height: 3px;
                    //     background: $primary-color;
                    //     opacity: 0;
                    //     @include transition(opacity, .175s);
                    // }

                    > a::before {
                        content:"";
                        position: absolute;
                        bottom: -45px;
                        right: 5px;
                        width: 0px;
                        height: 0px;
                        border-style: solid;
                        border-width: 0 7.5px 15px 7.5px;
                        border-color: transparent transparent #FFFFFF transparent;
                        transform: rotate(0deg);
                        opacity: 0;
                    }

                    &:hover {
                        background-color: $primary-color !important;
                        &::before{
                            opacity: 0.9;
                        }
                        > a::before {
                            opacity: 1;
                        }

                        > a::after {
                            opacity: 1;
                        }

                        .nav-dropdown--primary {
                            display: block;
                        }
                    }
                }
            }            
            li a:hover {
                color: $primary-color;                               
            }
            li.active a{
                color: $primary-color;             
            }
        }

        .nav-dropdown {
            &--primary {
                position: absolute;
                top: calc(100% + 20px);
                left: -50%;
                height: auto;
                padding: 1.25rem;
                //background: rgba($primary-color, .925);
                background: $white;
                color: $black;
                min-width: min(275px, 100vw);
                //width: 500px;
                border-radius: 10px;
                display: none;
                z-index: 5;

                &.products-mega{
                    left: -100%;
                    width: 1000px;
                }
                
                &, ul {
                    @extend %listless; 
                }
                
                li:hover {
                    a {
                        color: $black;
                    }
                }

                > li {

                    width: 100%;

                    a {
                        display: inline-block;
                        padding: 0;
                        text-align: left !important;

                        color: $black;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.featured > a {

                        //padding-left: 1rem;

                        &::before {
                            content: "\f08e";
                            @extend .far;
                            display: inline;
                            width: auto;
                            position: absolute;
                            right: -25px;
                            top: calc(50% - 0px);
                            transform: translateY(-50%);
                            @include font-size(14px, 16px); 
                            color: $primary-color;
                        }
                    }                    

                    &:hover, a:hover {
                        color: $black;
                    }

                    & + li {
                        padding-top: .6rem;
                    }
                }

                a {
                    letter-spacing: .65px;
                }

                &:hover {
                    opacity: 1;
                }
            }

            &--secondary {

                padding-top: .6rem !important;

                display: grid !important;
                grid-template-columns: 1fr 1fr;

                > li {
                    // float: left;
                    //width: 50%;

                    margin-right: 1.2rem !important;
                    padding-top: .6rem !important;

                    > a {
                        text-transform: none;
                        font-weight: normal;

                        & + p{
                            margin-top: 0.5rem;
                            color: $dgrey;
                            font-size: 12px;
                        }

                        &.cta-blue{
                            width: auto;
                            display: inline-block;
                            padding: 1rem 2rem;
                            padding-right: 3.5rem;
                            color: white;
                            text-transform: uppercase;
                            font-weight: bold;
                            &::before {
                                content: "\f08e";
                                @extend .far;
                                display: inline;
                                width: auto;
                                position: absolute;
                                right: 2rem;
                                top: calc(50% - 0px);
                                transform: translateY(-50%);
                                @include font-size(14px, 16px); 
                                color: $white;
                            }
                        }
                    }

                    & + li {
                        padding-top: .6rem !important;
                    }
                }
            }
        }       
        
        .products-mega{
            .featured{
                float: left;
                width: 35%;
                padding-top: 0;
                &:first-child{
                    width: calc(65% - 20px);
                    padding-right: 20px;
                    margin-right: 20px;
                    border-right: 1px solid $grey;
                }

                header{
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    font-size: 12px;
                    text-transform: uppercase;
                    border-bottom: 1px solid $grey;
                }
                a.top-level + p{
                    color: $dgrey;
                    font-size: 12px;
                }
            }
        }

        @include media(1160px, min){
           > ul{
                > li{                    
                    padding:24px 18px;
                }
           }           
        }
        @include media(1203px, min){
            
        }

        @include media(1024px, min){
            display: flex;
            justify-content: end; 
            
            .desk-hide{
                display: none;                 
            }
        }        
    }    
}

// Scroll header stick
.sub-menu{
    position: absolute; 
    left:0; top: 100%; 
    padding:3*$pad 0;
    //@include translateY(-20px);
    z-index:1001; 
    width: 200px !important;
    @include margin(0px);
    @include padding(10px);
    visibility: hidden;
    opacity: 0; 
    background: rgba(255,255,255, 1); 
    @include box-shadow(0 14px 14px -10px rgba(#000, 0.6));
    transition: transform 0.5s ease, opacity 0.5s ease;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    li{
        width: 100%;
        
        &.dropdown{
            &:after {
                content: "\f0de" !important;                
                color: #ccc; 
            }
            &:hover {
                &:after {
                    color: #ccc !important;
                }
            }
            @include media(992px){
                &:after {
                    display:none; 
                }
            }
        }

        a{
            text-align: left !important;
            padding:2*$pad 2*$pad 2*$pad 3*$pad !important;
            margin:0px !important;
            display: block !important;            
            height: auto !important; 
            font-size: 16px !important;
            color: #ccc !important; 
            font-size:14px !important;            

            &:hover{
                color: $primary-color !important;
            }            
        }         
    }    
}

@include media(992px){
    .sub-menu{
        top: 0px;
        padding:0px;
        position: relative;
        visibility: visible;
        opacity: 1;
        
        &:after {
            display:none;      
        }

        li{
            border-bottom: inherit;

            a{
                color: $white !important; 
                font-size:16px !important;

                &:hover{
                    color: $white !important;
                }

                .fa-angle-right{
                    display: none;
                }
                
            }
        }
    }

    header.siteHeader{
        .top-search{
            display:none;
        }
    }
}


// Mobile Menu specific styles
header.siteHeader {
    .toggle{        
        width: auto;          
        cursor: pointer;
    }    
    
    @include media(1024px, min){
        .toggle{
            display: none;
        }
    }

    .cta-orange.contact{
        padding: 0;
        font-size: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        vertical-align: top;
        &::before {
            content: "\f0e0";
            @extend .far;
            display: inline;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.4rem;
            color: $black;
        }
    }
}

$bar-width: 32px;
$bar-height: 3px;
$bar-spacing: 10px;
$bar-color: #ffffff;

.hamburger-menu {
    margin: auto;
    margin-top:0.5rem;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2; 
    cursor: pointer;
    float: right;
    
    .bar,
    .bar:after,
    .bar:before {
        width: $bar-width;
        height: $bar-height;
    }

    .bar {
        position: relative;
        transform: translateY($bar-spacing);
        background: $white;
        transition: all 0ms 300ms;
    
        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: 9px;
            background: $white;
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);

            
        }

        &::after{
            content: "";
            position: absolute;
            left: 0;
            top: 9px;
            background: $white;
            transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);            
        }
    }

    .mm-opening & .bar {
        background: rgba(255, 255, 255, 0); 
    }

    .mm-opening & .bar::after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }

    .mm-opening & .bar::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
}





