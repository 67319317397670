.page-search{
    h4{
        margin-bottom: 20px;
    }

    .search-form{ 
        .form-control{            
            margin-bottom: 10px;
        }
        .cta-blue-border{
            min-width: 200px;

            .fa-search{
                @include vertical-align;
                left:35px;
                @include font-size(14px, 16px);                
            }
        }
    }

    @include media(768px, min){
        .search-form{
            display: flex;
            align-items: center;
    
            .form-control{
                flex: 1;
                margin-right: 40px;
                margin-bottom: 10px;
            }            
        }
    }
}