.btn-banner-module{
    .banner-box{
        background-color: $dark-blue; 
        padding: 7*$pad;
        color: $white;
        @include border-radius(10px);
        

        figure{            
            margin: 0 0 30px 0;
            text-align: center;
        }

        main{
            width: auto;
            margin: auto;
            @include flex-width(450px);

            h4{
                color: $white;
                font-weight: $bold;
                text-transform: uppercase;
                @include font-size(22px, 16px);
                text-align: center;
            }
            ul{
                margin-bottom: 30px;
                padding: 0;
                list-style: none;

                li{
                    margin: 16px 0;
                    padding-left:20px;
                    position: relative;
                    @extend .fa-circle;
                    
                    &::before{
                        @extend .fas; 
                        position:absolute;
                        left:0px;
                        top:7px; 
                        color:$primary-color;
                        @include font-size(8px, 16px);              
                    } 
                }
            }

            @include media(700px, min) {
                @include flex-width(300px);
            }
        }

        @include media(1024px, min) {
            padding: 7*$pad 15*$pad;
        }
    } 

    @include media(700px, min){
        .banner-box{
            display: flex;
            align-items: center;
            justify-content: space-between;

            figure{
                width: auto;
                text-align: left;
                margin: 0 30px 0 0;
            }
            main{
                margin: 0;               
            }
        }
    }
}
