.social-media{
    &>ul{       
        width: auto;
        @extend %listless;

        li{
            margin:0 6px 0 0;            
            text-align: center;            

            &>a{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                @include border-radius(50%);
                @include font-size(20px, 16px);
                color: $primary-color;
                border:1px solid $primary-color;
                margin: 0;

                &:hover,
                &.active{
                    color: $secondary-color; 
                    border:1px solid $secondary-color; 
                }                

                img{
                    max-height: 24px;
                }
            }            
        }
    }    

    &.small{
        &>ul{ 
            li{
                margin:0 8px 0 0;    
                &>a{                    
                    width: 32px;
                    height: 32px;                    
                    @include font-size(14px, 16px);                    
                }
            }
        }
    }

    &.with-bg{
        &>ul{ 
            li{
                &>a{                    
                    color: $white;
                    background: $primary-color;                              
    
                    &:hover,
                    &.active{
                        color: $white;
                        background: $secondary-color; 
                    }                     
                }
            }
        }
    }
}


