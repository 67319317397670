#onetrust-banner-sdk {
    &:focus, button:focus {
        outline: none !important;
    }
}

.privacy-notice-link,
.ot-always-active,
#onetrust-pc-sdk .ot-cat-item > button,
#onetrust-pc-sdk .ot-acc-cntr > button,
#onetrust-pc-sdk li > button {
    color: $primary-color !important;
}

.ot-switch-nob {
    border-color: $primary-color !important;
    background: rgba($primary-color, 0.15) !important;

    &::before {
        background: rgba($primary-color, .75) !important;
    }
}

.ot-pc-refuse-all-handler,
#onetrust-reject-all-handler,
#onetrust-accept-btn-handler,
#accept-recommended-btn-handler {
    display: inline-block;
    min-width: 150px; 
    border: solid 1px transparent !important;
    border-radius: 4px !important;
    text-align: center !important;
    transition: all 0.175s ease-in-out !important;
    background: $primary-color !important;
    color: $white !important;
    font-weight: 500 !important;

    &:focus,
    &:hover {
        background: $secondary-color !important;
        border-color: $secondary-color !important;
        opacity: 1 !important;
        outline: 0 !important;
    }
}

.save-preference-btn-handler.onetrust-close-btn-handler,
#onetrust-pc-btn-handler {
    display: inline-block;
    min-width: 150px;
    border: solid 1px transparent !important;
    border-radius: 4px !important;
    text-align: center !important;
    transition: all .175s ease-in-out !important;
    background: $secondary-color !important;
    color: $white !important;
    font-weight: 500 !important;

    &:focus,
    &:hover {
        background: $primary-color !important;
        border-color: $primary-color !important;
        opacity: 1 !important;
        outline: 0 !important;
    }
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob::before {
    background: $primary-color !important;
}

.ot-floating-button__front,
.ot-floating-button__back {
    > button {
        text-align: center;

        > svg {
            display: inline-block;
        }
    }
}

.onetrust-close-btn-handler.onetrust-close-btn-ui.banner-close-button.ot-close-icon {
    display: none !important;
}

#ot-sdk-btn-floating {
    display: none !important;
}