.news-slider-module{    
  @extend .team-slider-module;  
}


.news-module{
  header{
    margin-bottom:12*$mar;    
  }

  .list-grid-3{
    justify-content:left;

    li{
      .cards{
        .card-news{
          main {
            min-height: auto;
          }
        }
      }
    }
  }

  blockquote{
    border-top:3px solid $primary-color;
    border-bottom:3px solid $primary-color;
    padding: 5*$pad 0;
    margin: 8*$pad 0;
    font-weight: $bold;
    line-height: 1.4;
    @include font-size(20px, 16px);
  }

  @include media(768px, min){
    .list-grid-3{   
      li{
        .cards{
          .card-news{
            main {
              margin-bottom: 10*$mar;
            }
          }
        }
      }
    }
  }

  a{
    color:$primary-color;

    &:hover{
      color: $secondary-color;
    }
  }
}
  

